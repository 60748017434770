import React from 'react';

import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/Seo';

const dateTimeOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: false,
  timeZone: 'Europe/Berlin',
};

interface IProps {
  data: any;
}

const VersionPage: React.FC<IProps> = ({
  data: {
    site: {
      buildTime,
      siteMetadata: { title },
    },
  },
}) => (
  <Layout>
    <SEO title={title} />
    <div className="hero">
      <div className="grid-container">
        <div className="grid-x grid-margin-x">
          <div className="cell small-12">
          <ul>
        <li>
          Last build:{' '}
          {new Intl.DateTimeFormat('de-DE', dateTimeOptions).format(Date.parse(buildTime))}
        </li>
      </ul>

          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default VersionPage;

export const pageQuery = graphql`
  query {
    site {
      buildTime
      siteMetadata {
        title
      }
    }
  }
`;